@import "../../config/palette.scss";

.opp-item {
  margin: 24px 0;
}

.opp-item .opp-item__cell {
  margin: 8px;
  height: 110px;
  border-radius: 12px;
}

.opp-item .opp-item__detail-box {
  padding: 12px;
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  & > * {
    margin-top: 5px;
  }
}

.opp-item .opp-item__inc-button-container {
  padding-right: 20px;
}

.opp-item .opp-item__inc-button {
  height: 2.8rem;
  width: 3.2rem;
  // undo MUI presets
  min-width: unset;
  background-color: $black;
  color: $white;
  border-radius: 8px;
  svg {
    font-size: 2.7rem;
  }
}

.opp-item .opp-item__deal {
  width: 110px;
}

.opp-item .opp-item__time {
  width: 160px;
}

.opp-item .opp-item__expected {
  width: 130px;
}

.opp-item .opp-item__post-button {
  padding: 6px 32px;
  font-size: 1.2rem;
  line-height: 1.2;
}

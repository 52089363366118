@import "../../config/palette.scss";

.discount-popup .MuiBackdrop-root {
  background-color: $white;
}

.discount-popup .discount-popup__container {
  border: $grey solid 1px;
  border-top-width: 10px;
  border-bottom-width: 10px;
  min-width: 750px;
}

.discount-popup .discount-popup__title {
  display: flex;
  flex-direction: column;
  padding: 0 14px 24px;
}

.discount-popup .discount-popup__title-text {
  align-self: center;
  padding: 15px 0;
}

.discount-popup .discount-popup__content {
  padding: 8px 16px;
}

.discount-popup .discount-popup__close-button {
  align-self: flex-end;
  color: $black;
  padding: 8px;
  margin-right: -11px;
  svg {
    font-size: 1.8rem;
  }
}

.discount-popup .discount-popup__description-text {
  color: $black;
  font-size: 1.5rem;
  text-align: center;
  margin: 0 54px 24px;
}